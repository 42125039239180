"use client";

import { useEffect } from "react";
import Error from "next/error";
import * as Sentry from "@sentry/nextjs";

export default function GlobalError({
  error,
  reset,
}: Readonly<{
  error: Error & { digest?: string };
  reset: () => void;
}>) {
  useEffect(() => {
    Sentry.captureException(error, {
      captureContext: { tags: { digest: error.digest } },
    });
  }, [error]);

  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
